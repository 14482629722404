<template>
  <div
    style="
      width: 100%;
      background-color: #f8f8f8;
      padding: 0 20px 20px;
      margin: 10px 0;
      border-radius: 20px;
    "
  >
    <div class="df jcb aic" style="width: 100%">
      <div>
        {{ comment.userName }}
        <span v-if="comment.toUserName"
          >&emsp;回复&emsp;{{ comment.toUserName }}</span
        >
      </div>
      <div style="color: #8a8c8e; font-size: 14px">
        {{ comment.feedbackTime }}
      </div>
    </div>
    <el-divider></el-divider>
    <el-form
      :model="comment"
      ref="fkForm"
      label-width="0px"
      class="fk-form-box"
    >
      <el-form-item
        style="margin-bottom: 5px"
        v-for="(item, index) in comment.taskDataFile"
        :key="comment.id + 'D' + index"
      >
        <el-input
          v-if="item.dataType === 0"
          type="textarea"
          v-model="item.fileUrl"
          readonly
        >
        </el-input>

        <div
          class="df"
          style="flex-wrap: wrap; width: 100%"
          v-else-if="item.dataType === 1"
        >
          <el-image
            fit="fill"
            v-for="(imgITem, indexIndex) in JSON.parse(item.fileUrl)"
            :key="item.id + 'I' + indexIndex"
            style="width: 148px; height: 148px; margin: 10px"
            :src="imgITem.url"
            :preview-src-list="JSON.parse(item.fileUrl).map((v) => v.url)"
          >
          </el-image>
        </div>
        <div class="df" style="flex-wrap: wrap" v-else-if="item.dataType === 2">
          <div
            v-for="(vItem, vIndex) in JSON.parse(item.fileUrl)"
            :key="comment.id + 'V' + vIndex"
            class="video-box"
          >
            <video :src="vItem.url" controls></video>
          </div>
        </div>
        <div
          v-else-if="item.dataType === 3"
          class="file-item df aic"
          style="cursor: pointer; width: 80%"
          @click="openFile(JSON.parse(item.fileUrl).url)"
        >
          <i
            class="el-icon-document"
            style="color: #909399; font-size: 22px"
          ></i>
          <div style="flex: 1; width: 0; padding: 0 20px">
            <div
              style="
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ JSON.parse(item.fileUrl).name }}
            </div>
          </div>
          <i
            class="el-icon-download"
            style="color: #909399; font-size: 22px; cursor: pointer"
          ></i>
        </div>
        <div
          v-else-if="item.dataType === 4"
          class="df aic jcc"
          style="width: 100%; overflow: hidden"
        >
          <el-empty
            description="语音信息,请前往小程序查看"
            :image-size="60"
          ></el-empty>
        </div>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <el-button type="text" class="disib blue" size="mini" @click="gotoFk"
        >回复</el-button
      >
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  methods: {
    openFile(url) {
      window.open(url);
    },
    gotoFk() {
      console.log("调用");
      this.$emit("gotoFk", this.comment);
    },
  },
  mounted() {
  },
};
</script>

<style  scoped lang="scss">
.jcb {
  justify-content: space-between;
}
.file-item {
  padding: 0 20px;
  .i-success {
    display: block;
  }
  .i-del {
    display: none;
  }
}
.file-item:hover {
  background-color: #fff;
  border-radius: 5px;
  .i-success {
    display: none;
  }
  .i-del {
    display: block;
  }
}
.video-box {
  width: 360px;
  height: 180px;
  margin: 5px 10px;
  background-color: #000;
  position: relative;
  video {
    width: 100%;
    height: 100%;
  }
}
/deep/ .el-divider {
  margin: 10px;
}
/deep/ .el-empty {
  padding: 0;
}
</style>