<template>
  <el-container class="shopList">
    <el-header class="box bb add aic">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>项目管理</el-breadcrumb-item>
        <el-breadcrumb-item>项目列表</el-breadcrumb-item>
        <el-breadcrumb-item>任务列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <el-tabs v-model="activeName">
        <el-tab-pane label="任务列表" name="first">
          <div class="pt20">
            <el-form :inline="true" :model="page" class="demo-form-inline">
              <el-form-item>
                <el-input
                  placeholder="任务名称"
                  v-model="page.keyword"
                  clearable
                  @clear="search"
                >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  type="primary"
                  @click="search"
                  size="medium"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  size="medium"
                  @click="dialogVisible = true"
                  >新增</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!-- <el-row class="box search mt20">
            <el-form :inline="true" :model="page" class="demo-form-inline">
              <el-col :span="4" class="mr10">
                <el-form-item size="medium">
                  <el-input
                    placeholder="任务名称"
                    v-model="page.keyword"
                    clearable
                    class="input-with-select"
                    @clear="search"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1" class="tl mr10">
                <el-form-item size="medium">
                  <div class="df">
                    <el-button
                      icon="el-icon-search"
                      type="primary"
                      @click="search"
                      >查询</el-button
                    >
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="1" class="tl mr10">
                <el-form-item size="medium">
                  <div class="df">
                    <el-button
                      icon="el-icon-plus"
                      type="primary"
                      @click="dialogVisible = true"
                      >新增</el-button
                    >
                  </div>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row> -->
          <el-table
            :data="list"
            style="width: 100%"
            :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="60"
            />
            <el-table-column
              prop="taskName"
              label="任务名称"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="createdTime"
              label="创建时间"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="handleDetail(scope.row)"
                  >查看任务</el-button
                >
                <!-- <el-button
              type="text"
              class="disib blue"
              size="mini"
              v-if="!scope.row.isEdit"
              @click="handleEdit(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              class="disib green"
              size="mini"
              v-else
              @click="handleEdit(scope.row)"
              >完成</el-button
            > -->

                <el-popconfirm
                  title="确定删除任务吗？"
                  icon="el-icon-warning"
                  icon-color="#F56C6C"
                  @confirm="handleDel(scope.row)"
                >
                  <el-button
                    type="text"
                    class="disib red"
                    size="mini"
                    slot="reference"
                    style="margin-left: 10px"
                    >删除</el-button
                  >
                </el-popconfirm>
                <!-- <el-button
              type="text"
              class="disib red"
              size="mini"
              v-else
              @click="
                scope.row.isEdit = false;
                scope.row.projectName = oldData;
                oldData = null;
              "
              >取消</el-button
            > -->
              </template>
            </el-table-column>
          </el-table>
          <div class="i-page disib mt20 df" style="justify-content: flex-end">
            <el-pagination
              background
              :page-size="page.limit"
              layout="total,prev, pager, next"
              :total="total"
              :current-page="page.page"
              @current-change="pageChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="资料列表" name="second">
          <material-list :projectId="Number($route.query.id)" />
        </el-tab-pane>
      </el-tabs>

      <el-dialog
        title="新增任务"
        :visible.sync="dialogVisible"
        width="1500px"
        @close="closeAdd"
        custom-class="task-box"
      >
        <div
          class="df"
          style="
            flex-direction: column;
            height: 800px;
            border-radius: 10px;
            padding: 20px;
            background-color: #fff;
          "
        >
          <div style="flex: 1; height: 0">
            <el-form
              :model="newTask"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="任务名称" prop="taskName">
                <el-input v-model="newTask.taskName"></el-input>
              </el-form-item>
              <el-form-item label="任务内容" prop="taskContent">
                <el-input
                  v-model="newTask.taskContent"
                  type="textarea"
                  autosize
                ></el-input>
              </el-form-item>
              <el-form-item label="截止时间" prop="endTime">
                <el-date-picker
                  v-model="newTask.endTime"
                  type="datetime"
                  placeholder="选择截止时间"
                  format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="被指派人">
                <el-tag
                  v-for="tag in userIdsCompute"
                  :key="tag.label"
                  closable
                  style="margin: 4px"
                  @close="
                    newTask.userIds = newTask.userIds.filter(
                      (v) => v !== tag.label
                    )
                  "
                >
                  {{ tag.value }}
                </el-tag>
                <el-dropdown @command="addUser">
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-plus"
                    style="margin: 4px"
                  >
                    新增人员
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="scroll-box">
                    <div
                      class="scroll-data"
                      style="max-height: 300px; overflow-y: scroll"
                      v-if="UnCheckedUser.length"
                    >
                      <el-dropdown-item
                        v-for="item in UnCheckedUser"
                        :key="item.label"
                        :command="item.label"
                        >{{ item.value }}</el-dropdown-item
                      >
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-form-item>
              <el-form-item label="资料文件">
                <el-tag
                  v-for="tag in newTask.data"
                  :key="tag.id"
                  closable
                  style="margin: 4px"
                  type="warning"
                  @close="
                    newTask.data = newTask.data.filter((v) => v.id !== tag.id)
                  "
                >
                  {{ tag.dataName }}
                </el-tag>
                <el-popover
                  placement="top"
                  width="600"
                  trigger="click"
                  @show="openFileList"
                >
                  <div
                    class="df"
                    style="
                      height: 460px;
                      flex-direction: column;
                      padding: 0 20px 20px;
                    "
                  >
                    <div class="pt20">
                      <el-form
                        :inline="true"
                        :model="fileQuery"
                        class="demo-form-inline"
                      >
                        <el-form-item>
                          <el-input
                            placeholder="任务名称"
                            v-model="fileQuery.keyword"
                            clearable
                            @clear="fileListSearch"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button
                            icon="el-icon-search"
                            type="primary"
                            size="medium"
                            @click="fileListSearch"
                            >查询</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                    <!-- <div>
                      <el-row class="box search mt20">
                        <el-form
                          :inline="true"
                          :model="fileQuery"
                          class="demo-form-inline"
                        >
                          <el-col :span="8" class="mr10">
                            <el-form-item size="medium">
                              <el-input
                                placeholder="任务名称"
                                v-model="fileQuery.keyword"
                                clearable
                                class="input-with-select"
                                @clear="fileListSearch"
                              >
                              </el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1" class="tl mr10">
                            <el-form-item size="medium">
                              <div class="df">
                                <el-button
                                  icon="el-icon-search"
                                  type="primary"
                                  @click="fileListSearch"
                                  >查询</el-button
                                >
                              </div>
                            </el-form-item>
                          </el-col>
                        </el-form>
                      </el-row>
                    </div> -->
                    <el-table
                      :data="fileList"
                      ref="fileForm"
                      style="flex: 1; height: 0"
                    >
                      <el-table-column width="55" label="状态">
                        <template slot-scope="scope">
                          <el-checkbox
                            @change="fileChange(scope.row)"
                            v-model="scope.row.isCheck"
                          ></el-checkbox>
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="150"
                        property="dataName"
                        label="文件名"
                      ></el-table-column>
                      <el-table-column
                        width="100"
                        property="createdTime"
                        label="上传时间"
                      ></el-table-column>
                    </el-table>
                    <div class="df" style="justify-content: flex-end">
                      <el-pagination
                        background
                        :page-size="fileQuery.limit"
                        layout="total,prev, pager, next"
                        :total="fileTotal"
                        :current-page="fileQuery.page"
                        @current-change="filePageChange"
                      >
                      </el-pagination>
                    </div>
                  </div>
                  <el-button
                    slot="reference"
                    icon="el-icon-plus"
                    type="primary"
                    size="small"
                    >添加文件</el-button
                  >
                </el-popover>
              </el-form-item>
            </el-form>
          </div>
          <div class="df" style="justify-content: flex-end">
            <el-button type="success" @click="addTask">提交</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        @close="closeDetail"
        title="任务详情"
        :visible.sync="detailVisible"
        width="1500px"
        custom-class="task-box"
      >
        <div
          v-if="taskDetail"
          class="scroll-data"
          style="
            height: 800px;
            border-radius: 10px;
            padding: 20px;
            background-color: #fff;
            overflow-y: scroll;
          "
          ref="fkBox"
        >
          <el-form
            :model="taskDetail"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="发布人" prop="createdName">
              <el-input v-model="taskDetail.createdName" readonly></el-input>
            </el-form-item>
            <el-form-item label="任务名称" prop="taskName">
              <el-input v-model="taskDetail.taskName" readonly></el-input>
            </el-form-item>
            <el-form-item label="任务内容" prop="taskContent">
              <el-input
                readonly
                v-model="taskDetail.taskContent"
                type="textarea"
                autosize
              ></el-input>
            </el-form-item>
            <el-form-item label="截止时间" prop="endTime">
              <el-date-picker
                readonly
                v-model="taskDetail.endTime"
                type="datetime"
                placeholder="选择截止时间"
                format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="被指派人" v-if="!taskDetail.fk">
              <div class="df" style="width: 100%">
                <div style="flex: 1; flex-warp: warp" class="df">
                  <el-tag
                    v-for="tag in taskDetail.userList"
                    :key="tag.userId"
                    closable
                    @close="delTaskUser(taskDetail.id, tag.userId)"
                    style="margin: 4px"
                  >
                    {{ tag.userName }}
                  </el-tag>
                </div>
                <div>
                  <el-dropdown
                    v-if="taskDetail.userList"
                    @command="(userId) => addTaskUser(taskDetail.id, userId)"
                  >
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-plus"
                      style="margin: 4px"
                    >
                      新增人员
                    </el-button>
                    <el-dropdown-menu slot="dropdown" class="scroll-box">
                      <div
                        class="scroll-data"
                        style="max-height: 300px; overflow-y: scroll"
                        v-if="UnCheckedUser.length"
                      >
                        <el-dropdown-item
                          v-for="item in allUser.filter(
                            (v) =>
                              !taskDetail.userList
                                .map((uItem) => uItem.userId)
                                .includes(v.label)
                          )"
                          :key="item.label"
                          :command="item.label"
                          >{{ item.value }}</el-dropdown-item
                        >
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="资料文件" v-if="taskDetail.taskData.length">
              <el-card
                class="box-card"
                v-for="item in taskDetail.taskData"
                :key="item.id"
              >
                <div slot="header" class="df aic jcb">
                  <span>{{ item.dataName }}</span>
                  <div></div>
                </div>
                <div
                  v-for="(fItem, fIndex) in item.dataFile"
                  :key="item.id + 'F' + fIndex"
                >
                  <el-divider v-if="fIndex"></el-divider>
                  <el-form>
                    <el-form-item label="名称" style="margin: 10px">
                      <el-input v-model="fItem.fileName" readonly></el-input>
                    </el-form-item>
                    <el-form-item
                      v-if="fItem.dataType === 0"
                      label="内容"
                      style="margin: 10px"
                    >
                      <el-input
                        readonly
                        v-model="fItem.fileUrl"
                        type="textarea"
                        autosize
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-else-if="fItem.dataType === 1"
                      label="内容"
                      style="margin: 10px"
                    >
                      <div class="df" style="flex-wrap: wrap; width: 100%">
                        <el-image
                          fit="fill"
                          v-for="(imgITem, indexIndex) in fItem.fileUrl"
                          :key="fItem.id + 'I' + indexIndex"
                          style="width: 148px; height: 148px; margin: 10px"
                          :src="imgITem.url"
                          :preview-src-list="fItem.fileUrl.map((v) => v.url)"
                        >
                        </el-image>
                      </div>
                    </el-form-item>
                    <el-form-item
                      v-else-if="fItem.dataType === 2"
                      label="内容"
                      style="margin: 10px"
                    >
                      <div class="df" style="flex-wrap: wrap">
                        <template v-if="fItem.fileUrl">
                          <div
                            v-for="(vItem, vIndex) in fItem.fileUrl"
                            :key="index + 'V' + vIndex"
                            class="video-box"
                          >
                            <video
                              :src="vItem.url"
                              :ref="index + 'V' + vIndex"
                              controls
                            ></video>
                          </div>
                        </template>
                      </div>
                    </el-form-item>
                    <el-form-item
                      v-else-if="fItem.dataType === 3"
                      label="内容"
                      style="margin: 10px"
                    >
                      <div style="width: 100%; text-align: left">
                        <div style="width: 60%">
                          <div
                            class="file-item df aic"
                            style="cursor: pointer"
                            @click="openFile(fItem.fileUrl)"
                          >
                            <i
                              class="el-icon-document"
                              style="color: #909399; font-size: 22px"
                            ></i>
                            <div style="flex: 1; width: 0; padding: 0 20px">
                              <div
                                style="
                                  width: 100%;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ fItem.fileUrl.name }}
                              </div>
                            </div>
                            <i
                              class="el-icon-download"
                              style="
                                color: #909399;
                                font-size: 22px;
                                cursor: pointer;
                              "
                            ></i>
                          </div>
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item
                      v-if="fItem.remark"
                      label="备注"
                      style="margin: 10px"
                    >
                      <el-input v-model="fItem.remark" readonly></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </el-card>
            </el-form-item>
            <el-form-item label="反馈列表" style="margin: 10px">
              <el-card
                class="box-card-executes"
                v-for="item in taskDetail.executes"
                :key="item.id"
              >
                <div slot="header" class="df aic jcb">
                  <div>{{ item.userName }}</div>
                  <div>
                    <div v-if="!taskDetail.fk">
                      <div v-if="item.readTime" style="text-align: right">
                        <div class="line-height:16px">
                          于任务发布<span style="color: #e56d00">{{
                            item.intervalTime
                          }}</span>
                          阅读
                        </div>
                        <div
                          style="
                            font-size: 12px;
                            color: #8a8c8e;
                            line-height: 12px;
                          "
                        >
                          具体阅读时间:{{ item.readTime }}
                        </div>
                        <div
                          v-if="item.zxFkTime"
                          style="
                            font-size: 12px;
                            color: #8a8c8e;
                            line-height: 12px;
                          "
                        >
                          最新反馈时间:{{ item.zxFkTime }}
                        </div>
                      </div>
                      <div v-else>
                        <el-tag type="info"> 未读 </el-tag>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="fkItem in item.taskFkData"
                  :key="fkItem.id"
                  style="padding: 0 20px; width: 100%"
                >
                  <Comment :comment="fkItem" :type="0" @gotoFk="gotoFk">
                    <template v-if="fkItem.response.length">
                      <div
                        style="
                          padding: 15px;
                          margin-left: 30px;
                          margin-top: 20px;
                          border-radius: 15px;
                          background-color: #fff;
                        "
                      >
                        <div
                          class=""
                          v-for="cItem in fkItem.response"
                          :key="cItem.id"
                        >
                          <Comment
                            :type="1"
                            :comment="cItem"
                            @gotoFk="gotoFk"
                          />
                        </div>
                      </div>
                    </template>
                  </Comment>
                </div>
              </el-card>
            </el-form-item>
            <el-form-item
              label="反馈"
              style="margin: 10px"
              v-if="taskDetail.fk"
            >
              <div style="width: 100%">
                <div
                  style="
                    padding: 0 20px;
                    margin-bottom: 15px;
                    color: #8a8c8e;
                    text-align: left;
                  "
                  v-if="fkForm.type === 2"
                >
                  回复:{{ fkForm.fkName }}
                </div>
                <el-form
                  :model="fkForm"
                  ref="fkForm"
                  label-width="0px"
                  class="fk-form-box"
                >
                  <el-form-item style="margin-bottom: 15px">
                    <el-input
                      type="textarea"
                      :rows="2"
                      v-model="fkForm.txtContent"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="fkForm.imgList.length"
                    style="margin-bottom: 15px"
                  >
                    <el-upload
                      action="#"
                      :file-list="fkForm.imgList"
                      list-type="picture-card"
                      :http-request="
                        (params) => {
                          addImg(params, item);
                        }
                      "
                      :on-remove="handleRemove"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item
                    style="margin-bottom: 15px"
                    v-if="fkForm.videoList.length"
                  >
                    <div class="df" style="flex-wrap: wrap">
                      <div
                        v-for="(vItem, vIndex) in fkForm.videoList"
                        :key="'V' + vIndex"
                        class="video-box"
                      >
                        <video :src="vItem.url"></video>
                        <div class="mask df aic jcc">
                          <i
                            @click="delVideo(vItem)"
                            class="el-icon-delete del-icon"
                            style="
                              color: #fff;
                              cursor: pointer;
                              font-size: 28px;
                            "
                          ></i>
                        </div>
                      </div>
                      <el-upload
                        action="#"
                        class="upload-demo"
                        :http-request="addVideo"
                        :show-file-list="false"
                      >
                        <div class="upload-video df aic jcc">
                          <i
                            class="el-icon-plus"
                            style="color: #8c939d; font-size: 28px"
                          ></i>
                        </div>
                      </el-upload>
                    </div>
                  </el-form-item>
                  <el-form-item
                    style="margin-bottom: 15px"
                    v-if="fkForm.toUser.length"
                  >
                    <el-tag
                      style="margin: 10px"
                      v-for="tItem in fkForm.toUser"
                      :key="tItem"
                      closable
                      @close="closeCommonUser(tItem)"
                      >{{
                        allUser.filter((v) => v.label === tItem)[0].value
                      }}</el-tag
                    >
                  </el-form-item>
                  <el-form-item
                    style="margin-bottom: 15px"
                    v-if="fkForm.fileContent && fkForm.fileContent.url"
                  >
                    <div class="file-item df aic" style="width: 80%">
                      <i
                        class="el-icon-document"
                        style="color: #909399; font-size: 22px"
                      ></i>
                      <div style="flex: 1; width: 0; padding: 0 20px">
                        <div
                          style="
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                        >
                          {{ fkForm.fileContent.name }}
                        </div>
                      </div>
                      <i
                        class="el-icon-success i-success"
                        style="color: #67c23a; font-size: 22px"
                      ></i>
                      <i
                        class="el-icon-error i-del"
                        @click="delFile()"
                        style="color: #909399; font-size: 22px; cursor: pointer"
                      ></i>
                    </div>
                  </el-form-item>
                </el-form>

                <div class="df aic" style="justify-content: flex-end">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="上传图片"
                    placement="bottom"
                  >
                    <span style="margin-right: 15px" class="upload-icon">
                      <el-upload
                        action="#"
                        class="upload-demo"
                        :http-request="addImg"
                        :show-file-list="false"
                      >
                        <i
                          class="el-icon-picture"
                          style="
                            color: #409eff;
                            font-size: 24px;
                            cursor: pointer;
                          "
                        ></i>
                      </el-upload>
                    </span>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="上传视频"
                    placement="bottom"
                  >
                    <span style="margin-right: 15px" class="upload-icon">
                      <el-upload
                        action="#"
                        class="upload-demo"
                        :http-request="addVideo"
                        :show-file-list="false"
                      >
                        <i
                          class="el-icon-video-camera-solid"
                          style="
                            color: #409eff;
                            font-size: 24px;
                            cursor: pointer;
                          "
                        ></i>
                      </el-upload>
                    </span>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="文件上传"
                    placement="bottom"
                  >
                    <span style="margin-right: 15px" class="upload-icon">
                      <el-upload
                        action="#"
                        class="upload-demo"
                        :http-request="addFile"
                        :show-file-list="false"
                      >
                        <i
                          class="el-icon-upload"
                          style="
                            color: #409eff;
                            font-size: 24px;
                            cursor: pointer;
                          "
                        ></i>
                      </el-upload>
                    </span>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="添加共享人员"
                    placement="bottom"
                  >
                    <el-dropdown
                      placement="top"
                      trigger="click"
                      @command="chooseCommentUser"
                    >
                      <i
                        class="el-icon-s-custom"
                        style="
                          color: #409eff;
                          font-size: 24px;
                          margin-right: 15px;
                          cursor: pointer;
                        "
                      ></i>
                      <el-dropdown-menu slot="dropdown">
                        <div style="max-height: 300px; overflow-y: scroll">
                          <el-dropdown-item
                            :command="item.label"
                            v-for="item in commentUserList.filter(
                              (v) => !fkForm.toUser.includes(v.label)
                            )"
                            :key="item.label"
                            >{{ item.value }}</el-dropdown-item
                          >
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-tooltip>

                  <el-button type="success" @click="fkSubmit">
                    {{ fkForm.type === 1 ? "反馈" : "回复" }}</el-button
                  >
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import api from "@/util/extra-api";
import { local } from "@/util/util";
import dayjs from "dayjs";
import MaterialList from "./components/MaterialList.vue";
import axios from "@/util/api";
import Comment from "./components/Comment";

export default {
  components: {
    MaterialList,
    Comment,
  },
  data() {
    let validateUserIds = (rule, value, callback) => {
      if (!value.length) {
        return callback(new Error("请选择至少一个被指派人"));
      } else {
        callback();
      }
    };
    return {
      page: {
        page: 1,
        limit: 10,
        keyword: "",
        projectId: null,
      },
      fileQuery: {
        page: 1,
        limit: 10,
        projectId: null,
        keyword: "",
      },
      list: [],
      detailList: [],
      total: 0,
      companyId: null,
      detailPage: {
        page: 1,
        limit: 10,
        keyword: "",
        user: null,
      },
      showAdd: false,
      newProjcet: {
        projectName: "",
      },
      detailTotal: 0,
      dialogTitle: "",
      //   dialogVisible: false,
      loading: false,
      oldData: null,
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        taskContent: [
          { required: true, message: "请输入任务内容", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择截止时间", trigger: "blur" },
        ],
        userIds: [{ validator: validateUserIds, trigger: "blur" }],
      },
      dialogVisible: false,
      activeName: "first",
      fileList: [],
      fileTotal: 0,
      allUser: [],
      newTask: {
        data: [],
        taskName: "",
        userIds: [],
        taskContent: "",
        endTime: "",
      },
      detailVisible: false,
      taskDetail: null,
      fkForm: {
        taskId: null,
        txtContent: "",
        fileContent: null,
        imgList: [],
        videoList: [],
        type: 1,
        fkName: "",
        toUser: [],
        parentId: null,
      },
      commentUserList: [],
    };
  },
  computed: {
    userIdsCompute() {
      return this.newTask.userIds.map((v) => {
        return this.allUser.filter((item) => item.label === v)[0];
      });
    },
    UnCheckedUser() {
      return this.allUser.filter(
        (v) => !this.newTask.userIds.includes(v.label)
      );
    },
    fileIdsCompute() {
      return this.newTask.data.map((v) => v.id);
    },
  },
  mounted() {
    this.companyId = Number(local.get("company_id"));
    this.page.projectId = this.$route.query.id;
    this.getAllUser();
    this.getList();
  },
  methods: {
    getCommentUser(id) {
      api
        .get(`/v1/pc/oa/task/userList?companyId=${this.companyId}`, {
          taskId: id,
        })
        .then((res) => {
          this.commentUserList = res.data.map((v) => {
            return {
              label: v.userId,
              value: v.userName,
            };
          });
        });
    },
    closeCommonUser(item) {
      this.$set(
        this.fkForm,
        "toUser",
        this.fkForm.toUser.filter((v) => v != item)
      );
    },
    chooseCommentUser(id) {
      this.fkForm.toUser.push(id);
    },
    gotoFk(parmas) {
      this.fkForm.type = 2;
      this.fkForm.parentId = parmas.id;
      this.fkForm.fkName = parmas.userName;
      this.$refs["fkBox"].scrollTop = this.$refs["fkBox"].scrollHeight;
    },
    delFile() {
      this.$set(this.fkForm, "fileContent", null);
    },
    delVideo(item) {
      this.$set(
        this.fkForm,
        "videoList",
        this.fkForm.videoList.filter((v) => v !== item)
      );
    },
    fkSubmit() {
      let data = {
        taskId: this.fkForm.taskId,
        txtContent: this.fkForm.txtContent,
        type: this.fkForm.type,
        isCommon: true,
      };
      if (this.fkForm.parentId) {
        data.parentId = this.fkForm.parentId;
      }
      if (this.fkForm.imgList.length) {
        data.imgContent = JSON.stringify(this.fkForm.imgList);
      }
      if (this.fkForm.videoList.length) {
        data.videoContent = JSON.stringify(this.fkForm.videoList);
      }
      if (this.fkForm.fileContent && this.fkForm.fileContent.url) {
        data.fileContent = JSON.stringify(this.fkForm.fileContent);
      }
      if (!this.fkForm.toUser.length) {
        data.toUserIds = [local.get("user").user_id];
      } else {
        if (!this.fkForm.toUser.includes(local.get("user").user_id)) {
          data.toUserIds = [local.get("user").user_id, ...this.fkForm.toUser];
        } else {
          data.toUserIds = [...this.fkForm.toUser];
        }
      }
      api
        .post(`/v1/pc/oa/task/fk?companyId=${this.companyId}`, data)
        .then((res) => {
          if (res.code === 200) {
            this.$refs["fkBox"].scrollTop = 0;
            this.handleDetail;
            this.fkForm = {
              taskId: null,
              txtContent: "",
              fileContent: [],
              imgList: [],
              videoList: [],
              type: 1,
              fkName: "",
              toUser: [],
              parentId: null,
            };
          }
        });
    },
    closeDetail() {
      this.fkForm = {
        taskId: null,
        txtContent: "",
        fileContent: [],
        imgList: [],
        videoList: [],
        type: 1,
        fkName: "",
        toUser: [],
        parentId: null,
      };
    },
    handleRemove(file) {
      this.$set(
        this.fkForm,
        "imgList",
        this.fkForm.imgList.filter((v) => v.name !== file.name)
      );
    },
    addFile(params) {
      const file = params.file,
        isLt10M = file.size / 1024 / 1024 < 10;
      // console.log(file, isVidoe);
      if (!isLt10M) {
        this.$message.error("只能上传文件大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("文件上传失败，请重新上传");
        } else {
          // console.log(resp.data);
          // console.log(data.form);
          this.$set(this.fkForm, "fileContent", {
            name: resp.name,
            url: resp.url,
          });
        }
      });
    },
    addVideo(params) {
      const file = params.file,
        fileType = file.type,
        isVidoe = fileType.includes("video"),
        isLt10M = file.size / 1024 / 1024 < 10;
      // console.log(file, isVidoe);
      if (!isVidoe) {
        this.$message.error("只能上传视频格式!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传文件大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("视频上传失败，请重新上传");
        } else {
          // console.log(resp.data);
          let videos = null;
          if (this.fkForm.videoList && this.fkForm.videoList) {
            videos = [...this.fkForm.videoList];
          } else {
            videos = [];
          }
          videos.push({
            name: resp.name,
            url: resp.url,
          });
          // console.log(data.form);
          this.$set(this.fkForm, "videoList", videos);
        }
      });
    },
    addImg(params) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          let imgs = null;
          if (this.fkForm.imgList && this.fkForm.imgList) {
            imgs = [...this.fkForm.imgList];
          } else {
            imgs = [];
          }
          imgs.push({
            name: resp.name,
            url: resp.url,
          });
          // console.log(data.form);
          this.$set(this.fkForm, "imgList", imgs);
          // console.log(data);
        }
      });
    },
    openFile(url) {
      window.open(url);
    },
    delTaskUser(id, userId) {
      api
        .post(`/v1/pc/oa/task/removePersonnel?companyId=${this.companyId}`, {
          id,
          userIds: [userId],
        })
        .then((res) => {
          api
            .get(`/v1/pc/oa/task/userList?companyId=${this.companyId}`, {
              taskId: id,
            })
            .then((res1) => {
              this.$set(this.taskDetail, "userList", res1.data);
            });
        });
    },
    addTaskUser(id, userId) {
      api
        .post(`/v1/pc/oa/task/addPersonnel?companyId=${this.companyId}`, {
          id,
          userIds: [userId],
        })
        .then((res) => {
          api
            .get(`/v1/pc/oa/task/userList?companyId=${this.companyId}`, {
              taskId: id,
            })
            .then((res1) => {
              this.$set(this.taskDetail, "userList", res1.data);
            });
        });
    },
    closeAdd() {
      this.newTask = {
        data: [],
        taskName: "",
        userIds: [],
        taskContent: "",
        endTime: "",
      };
    },
    fileChange(row) {
      console.log(row, row.isCheck);
      if (row.isCheck) {
        this.newTask.data.push(row);
      } else {
        this.newTask.data = this.newTask.data.filter((v) => v.id !== row.id);
      }
    },
    openFileList() {
      this.fileQuery.projectId = this.page.projectId;
      this.fileQuery.keyword = "";
      this.fileListSearch();
    },
    fileListSearch() {
      this.fileQuery.page = 1;
      this.getFileList();
    },
    getFileList() {
      api
        .get(`/v1/pc/oa/data/list?companyId=${this.companyId}`, this.fileQuery)
        .then((res) => {
          if (res.code === 200) {
            this.fileList = res.data.map((v) => {
              v.createdTime = v.createdTime.replace("T", " ");
              v.isCheck = this.fileIdsCompute.includes(v.id);
              return v;
            });
            this.fileList.forEach((item) => {});
            this.fileTotal = res.total;
          }
        });
    },
    addUser(id) {
      this.newTask.userIds.push(id);
    },
    handleUpload(params) {},
    handleChange() {},
    getAllUser() {
      api
        .get(`/v1/pc/oa/companyUserList?companyId=${this.companyId}`)
        .then((res) => {
          if (res.code === 200) {
            this.allUser = res.data;
          }
        });
    },
    getList() {
      api
        .get(`/v1/pc/oa/task/list?companyId=${this.companyId}`, this.page)
        .then((res) => {
          this.list = res.data.map((v) => {
            v.createdTime = v.createdTime.replace("T", " ");
            v.endTime = v.endTime.replace("T", " ");
            return v;
          });
          this.total = res.total;
        });
    },
    pageChange(e) {
      this.page.page = e;
      this.getList();
    },
    filePageChange(page) {
      this.fileQuery.page = page;
      this.getFileList();
    },
    search() {
      this.page.page = 1;
      this.getList();
    },
    addTask() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let { data, taskName, userIds, taskContent, endTime } = this.newTask;
          api
            .post(`/v1/pc/oa/task/add?companyId=${this.companyId}`, {
              dataIds: data.map((v) => v.id),
              taskName,
              userIds,
              taskContent,
              endTime: dayjs(endTime).format("YYYY-MM-DD HH:mm"),
              projectId: Number(this.$route.query.id),
            })
            .then((res) => {
              if (res.code === 200) {
                this.getList();
                this.dialogVisible = false;
              }
            });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    handleDel(row) {
      api
        .post(`/v1/pc/oa/task/remove?companyId=${this.companyId}`, {
          id: row.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.getList();
          }
        });
    },
    handleDetail(row) {
      this.getCommentUser(row.id);
      api
        .get(`/v1/pc/oa/task/detail?companyId=${this.companyId}`, {
          taskId: row.id,
        })
        .then((res) => {
          this.taskDetail = res.data;
          this.taskDetail.taskData.map((v) => {
            v.dataFile = v.dataFile.map((item) => {
              let val = {
                fileName: item.fileName,
                dataType: item.dataType,
                remark: item.remark,
              };
              if (item.dataType === 0) {
                val.fileUrl = item.fileUrl;
              } else {
                val.fileUrl = JSON.parse(item.fileUrl);
              }
              return val;
            });
            return v;
          });
          // console.log(this.taskDetail);
          this.fkForm.taskId = row.id;
          this.detailVisible = true;
          api
            .get(`/v1/pc/oa/task/userList?companyId=${this.companyId}`, {
              taskId: res.data.id,
            })
            .then((res1) => {
              this.$set(this.taskDetail, "userList", res1.data);
            });
        });
      //   this.loading = true;
      //   this.dialogTitle = row.userName + "的任务明细";
      //   this.detailPage.user = row.userId;
      //   this.detailPage.page = 1;
      //   this.getDetail();
      //   this.dialogVisible = true;
    },
    detailPageChange(e) {
      this.detailPage.page = e;
      this.getDetail();
    },
    searchDetail() {
      this.detailPage.page = 1;
      this.getDetail();
    },
    getDetail() {
      api
        .get(
          `/v1/pc/tally/taskDetail?companyId=${this.companyId}`,
          this.detailPage
        )
        .then((res) => {
          if (res.code === 200) {
            this.detailList = res.data;
            this.detailTotal = res.total;
            if (this.loading) {
              this.loading = false;
            }
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.green {
  color: #67c23a;
}
/deep/.task-box {
  border-radius: 15px;
  overflow: hidden;
  .el-dialog__body {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #eee !important;
    padding: 10px !important;
    .el-form-item__content {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.scroll-data::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.scroll-data::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
}
.box-card,
.box-card-executes {
  width: 100%;
  margin-bottom: 10px;
}
.jcb {
  justify-content: space-between;
}

/deep/ .el-card__header {
  padding: 5px 20px;
}
/deep/ .box-card-executes {
  .el-card__body {
    padding: 0;
  }
}
.video-box {
  width: 360px;
  height: 180px;
  margin: 5px 10px;
  background-color: #000;
  position: relative;
  video {
    width: 100%;
    height: 100%;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    .del-icon {
      display: none;
    }
  }
  .mask:hover {
    background-color: rgba(0, 0, 0, 0.5);
    .del-icon {
      display: block;
    }
  }
}
.file-item {
  padding: 0 20px;
  .i-success {
    display: block;
  }
  .i-del {
    display: none;
  }
}
.file-item:hover {
  background-color: #f2f6fc;
  border-radius: 5px;
  .i-success {
    display: none;
  }
  .i-del {
    display: block;
  }
}

.upload-icon {
  /deep/.el-upload--text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.fk-form-box {
  /deep/.el-form-item__content {
    width: 100% !important;
  }
}
.upload-video {
  background-color: #fbfdff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 360px;
  height: 180px;
  margin: 5px 10px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .el-table__empty-block{
  width:100% !important;
}
</style>