<template>
  <div>
    <div class="pt20" style="text-align:left;">
      <el-form :inline="true" :model="page" class="demo-form-inline">
        <el-form-item>
          <el-input
            placeholder="项目名"
            v-model="page.keyword"
            clearable
            class="input-with-select"
            @clear="search"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            @click="search"
            size="medium"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            size="medium"
            @click="dialogVisible = true"
            >添加资料</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-row class="box search mt20">
      <el-form :inline="true" :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
          <el-form-item size="medium">
            <el-input
              placeholder="项目名"
              v-model="page.keyword"
              clearable
              class="input-with-select"
              @clear="search"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="tl mr10">
          <el-form-item size="medium">
            <div class="df">
              <el-button icon="el-icon-search" type="primary" @click="search"
                >查询</el-button
              >
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="tl mr10">
          <el-form-item size="medium">
            <div class="df">
              <el-button
                icon="el-icon-plus"
                type="primary"
                @click="dialogVisible = true"
                >添加资料</el-button
              >
            </div>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row> -->
    <el-table
      :data="list"
      style="width: 100%"
      :header-cell-style="{
        background: '#eef1f6',
        color: '#606266',
      }"
    >
      <el-table-column type="index" align="center" label="序号" width="60" />
      <el-table-column
        prop="dataName"
        label="项目名称"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="createdTime"
        label="创建时间"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="disib blue"
            size="mini"
            @click="handleDetail(scope.row)"
            >查看资料</el-button
          >

          <el-popconfirm
            title="确定删除资料吗？"
            icon="el-icon-warning"
            icon-color="#F56C6C"
            @confirm="handleDel(scope.row)"
            ><el-button
              type="text"
              class="disib red"
              size="mini"
              slot="reference"
              style="margin-left: 10px"
              >删除资料</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="i-page disib mt20 df" style="justify-content: flex-end">
      <el-pagination
        background
        :page-size="page.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="page.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新增资料"
      :visible.sync="dialogVisible"
      width="1500px"
      @close="closeAdd"
      custom-class="task-box"
    >
      <div
        class="df"
        style="
          flex-direction: column;
          height: 800px;
          border-radius: 10px;
          padding: 20px 0;
          background-color: #fff;
        "
      >
        <div style="flex: 1; height: 0">
          <div
            class="scroll-box"
            style="overflow-y: scroll; max-height: 100%; padding: 0 20px"
          >
            <el-form
              :model="newData"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="资料名称" prop="dataName">
                <div class="df" style="width: 100%">
                  <el-input
                    v-model="newData.dataName"
                    style="flex: 1; margin-right: 120px"
                  ></el-input>
                  <el-button type="primary" @click="addFile">新增</el-button>
                </div>
              </el-form-item>
              <template v-for="(item, index) in newData.companyOaDataFileDtos">
                <el-divider :key="'divider' + index"></el-divider>
                <div
                  v-if="index !== 0"
                  style="padding: 10px 20px; justify-content: flex-end"
                  :key="'del' + index"
                  class="df"
                >
                  <i
                    @click="removeData(index)"
                    class="el-icon-remove i-del"
                    style="color: #f56c6c; font-size: 22px; cursor: pointer"
                  ></i>
                </div>
                <el-form-item
                  label="名称"
                  :prop="'companyOaDataFileDtos.' + index + '.fileName'"
                  :key="'name' + index"
                  :rules="[
                    {
                      required: true,
                      message: '请输入名称',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input v-model="item.fileName"></el-input>
                </el-form-item>
                <el-form-item
                  label="类型"
                  :prop="'companyOaDataFileDtos.' + index + '.dataType'"
                  :key="'type' + index"
                  :rules="[
                    {
                      required: true,
                      message: '请选择类型',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    v-model="item.dataType"
                    placeholder="请选择"
                    @change="(value) => changeOPtion(value, index)"
                  >
                    <el-option
                      v-for="item in options"
                      :key="'option' + item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="备注"
                  prop="dataContent"
                  :key="'remark' + index"
                >
                  <el-input v-model="item.dataContent"></el-input>
                </el-form-item>
                <el-form-item
                  v-if="item.dataType === null"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                >
                  <el-empty
                    style="margin-left: 60px"
                    description="请先选择类型"
                    :image-size="80"
                  ></el-empty>
                </el-form-item>
                <el-form-item
                  v-else-if="item.dataType === 0"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                  :rules="[
                    { required: true, message: '请输入内容', trigger: 'blur' },
                  ]"
                >
                  <el-input
                    v-model="item.fileContent"
                    type="textarea"
                    autosize
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-else-if="item.dataType === 1"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                  :rules="[
                    {
                      type: 'array',
                      required: true,
                      message: '请上传一个图片',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-upload
                    action="#"
                    list-type="picture-card"
                    :http-request="
                      (params) => {
                        addImg(params, item);
                      }
                    "
                    :on-preview="
                      (file) => handlePictureCardPreview(file, index)
                    "
                    :on-remove="(file, fileList) => handleRemove(file, index)"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item
                  v-else-if="item.dataType === 2"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                  :rules="[
                    {
                      type: 'array',
                      required: true,
                      message: '请上传一个视频',
                      trigger: 'change',
                    },
                  ]"
                >
                  <div class="df" style="flex-wrap: wrap">
                    <template v-if="item.fileContent">
                      <div
                        v-for="(vItem, vIndex) in item.fileContent"
                        :key="index + 'V' + vIndex"
                        class="video-box"
                      >
                        <video
                          :src="vItem.url"
                          :ref="index + 'V' + vIndex"
                        ></video>
                        <div class="mask df aic jcc">
                          <i
                            @click="delVideo(vItem, index)"
                            class="el-icon-delete del-icon"
                            style="
                              color: #fff;
                              cursor: pointer;
                              font-size: 28px;
                            "
                          ></i>
                        </div>
                      </div>
                    </template>
                    <el-upload
                      action="#"
                      class="upload-demo"
                      :http-request="
                        (params) => {
                          addVideo(params, item);
                        }
                      "
                      :show-file-list="false"
                    >
                      <div class="upload-video df aic jcc">
                        <i
                          class="el-icon-plus"
                          style="color: #8c939d; font-size: 28px"
                        ></i>
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item
                  v-else-if="item.dataType === 3"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                  :rules="[
                    {
                      required: true,
                      message: '请上传文件',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <div style="width: 100%; text-align: left">
                    <el-upload
                      v-if="
                        !item.fileContent ||
                        (item.fileContent && !item.fileContent.name)
                      "
                      class="upload-demo"
                      action="#"
                      multiple
                      :http-request="
                        (params) => {
                          addFileData(params, item);
                        }
                      "
                      :show-file-list="false"
                    >
                      <el-button size="small" type="primary"
                        >点击上传</el-button
                      >
                    </el-upload>
                    <div style="width: 60%" v-else>
                      <div class="file-item df aic">
                        <i
                          class="el-icon-document"
                          style="color: #909399; font-size: 22px"
                        ></i>
                        <div style="flex: 1; width: 0; padding: 0 20px">
                          <div
                            style="
                              width: 100%;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            {{ item.fileContent.name }}
                          </div>
                        </div>
                        <div>
                          <i
                            class="el-icon-success i-success"
                            style="color: #67c23a; font-size: 22px"
                          ></i>
                          <i
                            class="el-icon-error i-del"
                            @click="delFile(index)"
                            style="
                              color: #909399;
                              font-size: 22px;
                              cursor: pointer;
                            "
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </template>
            </el-form>
          </div>
        </div>
        <div class="df" style="justify-content: flex-end; padding: 0 20px">
          <el-button type="success" @click="addData">提交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="资料详情"
      :visible.sync="showDetail"
      width="1500px"
      @close="closeDetail"
      custom-class="task-box"
    >
      <div
        v-if="showDetail"
        class="df"
        style="
          flex-direction: column;
          height: 800px;
          border-radius: 10px;
          padding: 20px 0;
          background-color: #fff;
        "
      >
        <div style="flex: 1; height: 0">
          <div
            class="scroll-box"
            style="overflow-y: scroll; max-height: 100%; padding: 0 20px"
          >
            <el-form
              :model="detailData"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <template
                v-for="(item, index) in detailData.companyOaDataFileDtos"
              >
                <el-divider :key="'divider' + index"></el-divider>
                <el-form-item
                  label="名称"
                  :prop="'companyOaDataFileDtos.' + index + '.fileName'"
                  :key="'name' + index"
                  :rules="[
                    {
                      required: true,
                      message: '请输入名称',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input v-model="item.fileName" readonly></el-input>
                </el-form-item>
                <el-form-item
                  v-if="item.dataType === 0"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                  :rules="[
                    { required: true, message: '请输入内容', trigger: 'blur' },
                  ]"
                >
                  <el-input
                    readonly
                    v-model="item.fileContent"
                    type="textarea"
                    autosize
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-else-if="item.dataType === 1"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                  :rules="[
                    {
                      type: 'array',
                      required: true,
                      message: '请上传一个图片',
                      trigger: 'change',
                    },
                  ]"
                >
                  <div class="df" style="flex-wrap: wrap; width: 100%">
                    <el-image
                      fit="fill"
                      v-for="(imgITem, indexIndex) in item.fileContent"
                      :key="item.id + 'I' + indexIndex"
                      style="width: 148px; height: 148px; margin: 10px"
                      :src="imgITem.url"
                      :preview-src-list="item.fileContent.map((v) => v.url)"
                    >
                    </el-image>
                  </div>
                </el-form-item>
                <el-form-item
                  v-else-if="item.dataType === 2"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                  :rules="[
                    {
                      type: 'array',
                      required: true,
                      message: '请上传一个视频',
                      trigger: 'change',
                    },
                  ]"
                >
                  <div class="df" style="flex-wrap: wrap">
                    <template v-if="item.fileContent">
                      <div
                        v-for="(vItem, vIndex) in item.fileContent"
                        :key="index + 'V' + vIndex"
                        class="video-box"
                      >
                        <video
                          :src="vItem.url"
                          :ref="index + 'V' + vIndex"
                          controls
                        ></video>
                      </div>
                    </template>
                  </div>
                </el-form-item>
                <el-form-item
                  v-else-if="item.dataType === 3"
                  label="内容"
                  :key="'file' + index"
                  :prop="'companyOaDataFileDtos.' + index + '.fileContent'"
                  :rules="[
                    {
                      required: true,
                      message: '请上传文件',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <div style="width: 100%; text-align: left">
                    <div style="width: 60%">
                      <div
                        class="file-item df aic"
                        style="cursor: pointer"
                        @click="openFile(item.fileContent)"
                      >
                        <i
                          class="el-icon-document"
                          style="color: #909399; font-size: 22px"
                        ></i>
                        <div style="flex: 1; width: 0; padding: 0 20px">
                          <div
                            style="
                              width: 100%;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            {{ item.fileContent.name }}
                          </div>
                        </div>
                        <i
                          class="el-icon-download"
                          style="
                            color: #909399;
                            font-size: 22px;
                            cursor: pointer;
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item
                  v-if="item.dataContent"
                  label="备注"
                  prop="dataContent"
                  :key="'remark' + index"
                >
                  <el-input v-model="item.dataContent" readonly></el-input>
                </el-form-item>
              </template>
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer"
      :url-list="previewUrl"
      :on-close="closeViewer"
    />
  </div>
</template>

<script>
import api from "@/util/extra-api";
import { local } from "@/util/util";
import axios from "@/util/api";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      showViewer: false,
      previewUrl: [],
      page: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      companyId: null,
      list: [],
      total: 0,
      dialogVisible: false,
      showDetail: false,
      detailData: null,
      newData: {
        dataName: "",
        companyOaDataFileDtos: [
          {
            dataContent: "",
            dataType: null,
            fileContent: null,
            fileName: "",
          },
        ],
      },
      rules: {
        dataName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
      },
      options: [
        {
          label: "文本",
          value: 0,
        },
        {
          label: "图片",
          value: 1,
        },
        {
          label: "视频",
          value: 2,
        },
        {
          label: "文件",
          value: 3,
        },
      ],
    };
  },
  props: {
    projectId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  mounted() {
    this.companyId = Number(local.get("company_id"));
    this.getList();
  },
  methods: {
    openFile(data) {
      window.open(data.url);
    },
    closeDetail() {
      detailData = null;
    },
    removeData(index) {
      this.$set(
        this.newData,
        "companyOaDataFileDtos",
        this.newData.companyOaDataFileDtos.filter((v, i) => i !== index)
      );
    },
    delFile(index) {
      this.$set(this.newData.companyOaDataFileDtos[index], "fileContent", null);
    },
    handleViewer(url) {
      this.previewUrl = url;
      this.showViewer = true;
    },
    changeOPtion(value, index) {
      this.$set(this.newData.companyOaDataFileDtos[index], "fileContent", null);
    },
    addFile() {
      this.newData.companyOaDataFileDtos.push({
        dataContent: "",
        dataType: null,
        fileContent: null,
        fileName: "",
      });
    },
    delVideo(vItem, index) {
      this.$set(
        this.newData.companyOaDataFileDtos[index],
        "fileContent",
        this.newData.companyOaDataFileDtos[index].fileContent.filter(
          (v) => v !== vItem
        )
      );
    },
    getList() {
      api
        .get(`/v1/pc/oa/data/list?companyId=${this.companyId}`, {
          ...this.page,
          projectId: this.projectId,
        })
        .then((res) => {
          this.list = res.data.map((v) => {
            v.createdTime = v.createdTime.replace("T", " ");
            return v;
          });
          this.total = res.total;
        });
    },
    pageChange(e) {
      this.page.page = e;
      this.getList();
    },
    search() {
      this.page.page = 1;
      this.getList();
    },
    handleDetail(row) {
      api
        .get(`/v1/pc/oa/data/detail?companyId=${this.companyId}`, {
          dataId: row.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.detailData = {
              dataName: res.data.dataName,
              companyOaDataFileDtos: res.data.dataFile.map((v) => {
                let val = {
                  dataType: v.dataType,
                  fileName: v.fileName,
                  dataContent: v.remark,
                };
                if (v.dataType) {
                  val.fileContent = JSON.parse(v.fileUrl);
                } else {
                  val.fileContent = v.fileUrl;
                }
                return val;
              }),
            };
            this.showDetail = true;
          }
        });
    },
    handleDel(row) {
      api
        .post(`/v1/pc/oa/data/remove?companyId=${this.companyId}`, {
          id: row.id,
        })
        .then((res) => {
          if (this.page.page !== 1 && this.list.length === 0) {
            this.page.page--;
          }
          this.getList();
        });
    },
    closeAdd() {
      this.newData = {
        dataName: "",
        companyOaDataFileDtos: [
          {
            dataContent: "",
            dataType: null,
            fileContent: null,
            fileName: "",
          },
        ],
      };
    },
    addData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let data = {
            dataName: this.newData.dataName,
            companyOaDataFileDtos: this.newData.companyOaDataFileDtos.map(
              (v) => {
                let val = JSON.parse(JSON.stringify(v));
                if (Array.isArray(v.fileContent)) {
                  val.fileContent = JSON.stringify(val.fileContent);
                } else if (typeof v.fileContent === "object") {
                  val.fileContent = JSON.stringify(val.fileContent);
                }
                return val;
              }
            ),
          };

          api
            .post(`/v1/pc/oa/data/add?companyId=${this.companyId}`, {
              ...data,
              projectId: this.projectId,
            })
            .then((res) => {
              if (res.code === 200) {
                this.dialogVisible = false;
                this.getList();
              }
            });
        } else {
          return false;
        }
      });
      // /v1/pc/oa/data/add
    },
    addFileData(params, data) {
      const file = params.file,
        isLt10M = file.size / 1024 / 1024 < 10;
      // pdf、word、excel
      if (!isLt10M) {
        this.$message.error("只能上传文件大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("文件上传失败，请重新上传");
        } else {
          // console.log(resp.data);

          this.$set(data, "fileContent", {
            name: resp.name,
            url: resp.url,
          });
        }
      });
    },
    addVideo(params, data) {
      const file = params.file,
        fileType = file.type,
        isVidoe = fileType.includes("video"),
        isLt10M = file.size / 1024 / 1024 < 10;
      // console.log(file, isVidoe);
      if (!isVidoe) {
        this.$message.error("只能上传视频格式!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传文件大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("视频上传失败，请重新上传");
        } else {
          // console.log(resp.data);
          let videos = null;
          if (data.fileContent && data.fileContent.length) {
            videos = [...data.fileContent];
          } else {
            videos = [];
          }

          videos.push({
            name: resp.name,
            url: resp.url,
          });
          this.$set(data, "fileContent", videos);
        }
      });
    },
    addImg(params, data) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          let imgs = null;
          if (data.fileContent && data.fileContent.length) {
            imgs = [...data.fileContent];
          } else {
            imgs = [];
          }
          imgs.push({
            name: resp.name,
            url: resp.url,
          });
          // console.log(data.form);
          this.$set(data, "fileContent", imgs);
          // console.log(data);
        }
      });
    },
    handlePictureCardPreview(file, index) {
      this.previewUrl = this.newData.companyOaDataFileDtos[index].fileContent
        .filter((v) => v.name === file.name)
        .map((v) => v.url);
      this.showViewer = true;
    },
    handleRemove(file, index) {
      this.$set(
        this.newData.companyOaDataFileDtos[index],
        "fileContent",
        this.newData.companyOaDataFileDtos[index].fileContent.filter(
          (v) => v.name !== file.name
        )
      );
    },
    closeViewer() {
      this.showViewer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-video {
  background-color: #fbfdff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 360px;
  height: 180px;
  margin: 5px 10px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.video-box {
  width: 360px;
  height: 180px;
  margin: 5px 10px;
  background-color: #000;
  position: relative;
  video {
    width: 100%;
    height: 100%;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    .del-icon {
      display: none;
    }
  }
  .mask:hover {
    background-color: rgba(0, 0, 0, 0.5);
    .del-icon {
      display: block;
    }
  }
}
.scroll-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.scroll-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
}
.file-item {
  padding: 0 20px;
  .i-success {
    display: block;
  }
  .i-del {
    display: none;
  }
}
.file-item:hover {
  background-color: #f2f6fc;
  border-radius: 5px;
  .i-success {
    display: none;
  }
  .i-del {
    display: block;
  }
}
</style>